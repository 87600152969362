<template>
  <div class="content content_in">
    <div class="title-row d-flex row justify-content-between">
       <div class="col-12 col-md-auto">
        <h1>{{ $t("dashboard.table.title") }}</h1>
      </div>
    </div>
    <div class="row pb-4">
      <div class="col-12">
        <Tabs
          :tabs="tabs"
          class="formats-tab"
          :currentTab="currentTab"
          @setTab="setCurrentTab"
        />
      </div>
    </div>

    <table
      v-if="filteredRows.length > 0 && !loading"
      class="table table-top"
      role="grid"
    >
      <thead>
        <tr v-for="(item, index) in displayColumns" :key="index">
          <th @click="sort(item.field)">
            <svg
              v-show="item.field.includes('_mob')"
              v-svg
              symbol="mobile"
              size="0 0 11 18"
            />
            <svg
              v-show="item.field.includes('_pc')"
              v-svg
              symbol="pc"
              size="0 0 18 15"
            />
            {{ formatLabel(item.label) }}
            <span class="label" v-if="sortKey === item.field">
              <svg
                :class="{ desc: sortOrder === 'desc' }"
                v-svg
                symbol="triangle"
                size="0 0 16 16"
              />
            </span>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(item, index) in filteredRows" :key="index">
          <td v-if="item.iso">
            <gb-flag width="24px" height="16px" :code="item.iso" />
            <span class="flag-text">
              {{
                $i18n.locale === "ru"
                  ? item.country_name_ru
                  : item.country_name_en
              }}
            </span>
          </td>

          <td v-if="item.avg_price_mob || item.avg_price_mob === 0">
            <b>$ {{ item.avg_price_mob || 0 }}</b>
          </td>
          <td v-if="item.avg_price_pc || item.avg_price_pc === 0">
            <b>$ {{ item.avg_price_pc || 0 }}</b>
          </td>
          <td
            v-if="
              item.avg_profit_per_thousand_mob ||
              item.avg_profit_per_thousand_mob === 0
            "
          >
            <b>$ {{ item.avg_profit_per_thousand_mob || 0 }}</b>
          </td>
          <td
            v-if="
              item.avg_profit_per_thousand_pc ||
              item.avg_profit_per_thousand_pc === 0
            "
          >
            <b>$ {{ item.avg_profit_per_thousand_pc || 0 }}</b>
          </td>

          <td v-if="item.group_column">
            <gb-flag width="24px" height="16px" :code="item.group_column" />
            <span class="flag-text">
              {{
                $i18n.locale === "ru"
                  ? item.country_name_ru
                  : item.country_name_en
              }}
            </span>
          </td>

          <td v-if="item.avg_cpc_mob || item.avg_cpc_mob === 0">
            <b>$ {{ item.avg_cpc_mob || 0 }}</b>
          </td>

          <td v-if="item.avg_cpm_mob || item.avg_cpm_mob === 0">
            <b>$ {{ item.avg_cpm_mob || 0 }}</b>
          </td>
          <td v-if="item.avg_cpc_pc || item.avg_cpc_pc === 0">
            <b>$ {{ item.avg_cpc_pc || 0 }}</b>
          </td>
          <td v-if="item.avg_cpm_pc || item.avg_cpm_pc === 0">
            <b>$ {{ item.avg_cpm_pc || 0 }}</b>
          </td>
        </tr>
      </tbody>
    </table>

    <div
      v-else-if="filteredRows.length === 0 && !loading"
      class="no-information"
    >
      <h3 class="text-center pt-3">{{ $t("any_results") }}</h3>
    </div>
  </div>
</template>


<script>
import "@/assets/css/dashboard.scss";
import DashboardService from "@/api/dashboardService";
import Tabs from "@/components/statictics/Tabs.vue";

export default {
  name: "Top",
  beforeMount() {
    document.title = `Partners.House | ${this.$t("top_countries")}`;
  },
  beforeUpdate() {
    document.title = `Partners.House | ${this.$t("top_countries")}`;
  },
  components: {
    Tabs,
  },
  data() {
    return {
      loading: true,
      columnsPush: [
        {
          label: "dashboard.table.country",
          field: "country_name",
        },
        {
          label: "dashboard.table.average_price_mob",
          field: "avg_price_mob",
        },
        {
          label: "dashboard.table.average_price_pc",
          field: "avg_price_pc",
        },
        {
          label: "dashboard.table.average_income_mob",
          field: "avg_profit_per_thousand_mob",
        },
        {
          label: "dashboard.table.average_income_pc",
          field: "avg_profit_per_thousand_pc",
        },
      ],
      columns: [
        {
          label: "dashboard.table.country",
          field: "group_column",
        },
        {
          label: "dashboard.table.average_cpc_mob",
          field: "avg_cpc_mob",
        },
        {
          label: "dashboard.table.average_cpm_mob",
          field: "avg_cpm_mob",
        },
        {
          label: "dashboard.table.average_cpc_pc",
          field: "avg_cpc_pc",
        },
        {
          label: "dashboard.table.average_cpm_pc",
          field: "avg_cpm_pc",
        },
      ],
      pushRows: [],
      onClickRows: [],
      inPageRows: [],
      currentTab: "webpush",
      tabs: ["webpush", "inpage", "onclick"],
      sortKey: "",
      sortOrder: "desc",
    };
  },
  mounted() {
    this.getAllCountries();
    this.columnsPush[0].field = this.checkCountry;
  },
  watch: {
    "$i18n.locale": function () {
      this.columnsPush[0].field = this.checkCountry;
    },
  },
  computed: {
    displayColumns() {
      return this.currentTab === "webpush" ? this.columnsPush : this.columns;
    },
    checkCountry() {
      return this.$i18n.locale === "ru" ? "country_name_ru" : "country_name_en";
    },
    filteredRows() {
      let rows = [];
      if (this.currentTab === "webpush") rows = this.pushRows.slice();
      else if (this.currentTab === "inpage") rows = this.inPageRows.slice();
      else rows = this.onClickRows.slice();

      if (this.sortKey) {
        rows.sort((a, b) => {
          let modifier = this.sortOrder === "desc" ? -1 : 1;
          if (a[this.sortKey] < b[this.sortKey]) return -1 * modifier;
          if (a[this.sortKey] > b[this.sortKey]) return 1 * modifier;
          return 0;
        });
      }
      return rows;
    },
  },
  methods: {
    setCurrentTab(tab) {
      this.currentTab = tab;
      this.sortOrder = "";
      this.sortKey = "";
    },
    sort(key) {
      if (this.sortKey === key) {
        if (this.sortOrder === "asc") {
          this.sortOrder = "desc";
        } else if (this.sortOrder === "desc") {
          this.sortOrder = "";
          this.sortKey = "";
        } else {
          this.sortOrder = "asc";
        }
      } else {
        this.sortKey = key;
        this.sortOrder = "asc";
      }
    },
    getAllCountries() {
      DashboardService.getAllCounties()
        .then((res) => {
          if (res && res.status === 200) {
            this.pushRows = res.data.push;
            this.inPageRows = res.data.inpage;
            this.onClickRows = res.data.onclick;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    formatLabel(el) {
      return this.$t(el);
    },
  },
};
</script>


<style lang="scss" scoped>
.table.table-top {
  thead {
    th {
      width: -webkit-fill-available;
      border: none;
      cursor: pointer;
      font-size: 14px;
      font-weight: 400;
      padding-bottom: 0;
    }
    tr {
      display: flex;
    }

    display: inline-flex;
    width: 100%;
  }
  tbody {
    tr {
      background: #f7f7f9;
      border-radius: 8px;
      margin: 10px 0;
      display: flex;
    }

    width: 100%;
  }
  thead tr,
  tbody td {
    width: 20%;
    border-top: none;
  }
}
span.label {
  position: absolute;
  svg.desc {
    transform: rotate(180deg);
    margin-top: -10px;
  }
}

.formats-tab ::v-deep li {
  width: 33%;
}

@media screen and (max-width: 678px) {
  .table.table-top {
    overflow-x: auto;
    display: block;
  }
  .table.table-top thead tr {
    width: fit-content;
  }
  .table.table-top thead tr:first-child th,
  .table.table-top tbody tr td:first-child {
    width: 150px !important;
    overflow: hidden;
  }

  .table.table-top th,
  .table.table-top td {
    min-width: 130px;
    position: relative;
    width: fit-content !important;
  }
}
</style>