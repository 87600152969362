<template>
  <ul class="tabs justify-content-start mb-0">
    <li
      v-for="(tab, index) in tabs"
      :key="index"
      class="col-sm-12 col-lg-auto pl-1 pr-1"
    >
      <button
        @click.prevent="$emit('setTab', tab)"
        :class="{ active: currentTab === tab }"
      >
        {{ $t(`statistic.tabs.${tab}`) }}
      </button>
    </li>
  </ul>
</template>

<style scoped lang="scss">
@media screen and (max-width: 990px) {
  .tabs {
    flex-flow: row wrap;
    li {
      flex: 1;
      button {
        padding: 8px;
      }
    }
  }
}
</style>

<script>
import "@/assets/css/_tubs.scss";

export default {
  name: "Tabs",
  props: {
    tabs: {
      type: Array,
      required: true,
    },
    setTab: {
      type: Function,
      default: () => {},
    },
    currentTab: {
      type: String,
      default: "",
    },
  },
};
</script>
